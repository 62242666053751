@import "~bootstrap/scss/bootstrap.scss";
@import "~swiper/css/swiper.css";
@import "~animate.css/animate.min.css";
@import "~lightgallery.js/dist/css/lightgallery.css";
@import "~react-modal-video/scss/modal-video.scss";
@import "../css/icons.min.css";

@import "variables";
@import "helper";
@import "common";
@import "preloader";
@import "header";
@import "slider";
@import "support";
@import "section-title";
@import "product";
@import "blog";
@import "footer";
@import "banner";
@import "suscribe";
@import "about";
@import "image-slider";
@import "testimonial";
@import "brand-logo";
@import "timers";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";
@import "compare";
@import "checkout";
@import "contact";
@import "blog-details";
@import "login-register";
@import "my-account";
@import "404";
@import "video-popup";


:root {
    --theme-color: red;
}

.css-m2td1u {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0000004a;
    z-index: 99;

    div {
        left: auto !important;
        top: auto !important;
    }
}

.css-16vsn7r {
    left: auto !important;
    top: auto !important;
}

.checkout-heading {
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    border-top: 5px solid $theme-color;
    color: #5b5858;
    height: 55px;
    // width: 469px;
    line-height: 1.6;
    padding: 5px 30px;
    margin-bottom: 30px;
}

.star-ratings {
    top: -3px;
}

.css-8z0ti4 {
    left: auto !important;
    top: auto !important;
}

.error-msg {
    color: red !important;
    font-size: 13px !important;
    font-weight: 300 !important;
}